export default class HeaderText {
  constructor() {
    this.section = document.querySelectorAll('.s-header-text');
  }

  set = (i) => {
    this.font = {
      h2: i.querySelectorAll('h2'),
      h3: i.querySelectorAll('.s-text-body h3'),
    };

    [...this.font.h3].forEach((i) => {
      i.classList.add('f-hr');
    });

    [...this.font.h2].forEach((i) => {
      i.classList.add('f-hl');
    });
  }

  init() {
    [...this.section].forEach((i) => {
      this.set(i);
    });
  }
}
