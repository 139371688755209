import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';
import CustomEase from 'gsap/CustomEase';
import config from '../../utils/config';
import Scroll from '../../utils/scroll';

gsap.registerPlugin(CustomEase);

export default class Lookbook {
  constructor() {
    this.page = document.querySelectorAll('.a-page');
    this.sections = document.querySelectorAll('[data-products]');
    this.cursor = document.querySelector('#cursor div');

    CustomEase.create('exo', config.ease);

    this.tY = 0;
    this.pY = 0;

    this.type = undefined;

    this.closing = false;

    this.request = new XMLHttpRequest();
  }

  init() {
    if (this.sections.length === 0) return;

    this.events();
  }

  events = () => {
    [...this.sections].forEach((i) => {
      i.addEventListener('click', () => {
        this.element = i;
        if (this.element.dataset.type === 'image') {
          this.type = 'image';
        } else {
          this.type = 'video';
        }
        this.create();
      });
    });
  }

  create = () => {
    document.body.style.overflow = 'hidden';
    this.cache = Math.abs(document.querySelector('.a-page').getBoundingClientRect().top);

    this.lookbook = document.createElement('div');
    this.lookbook.setAttribute('id', 'lookbook');
    document.body.appendChild(this.lookbook);

    this.viewer = document.createElement('div');
    this.viewer.classList.add('l-viewer');
    this.lookbook.appendChild(this.viewer);

    if (this.type === 'image' || Sniffer.isDevice) {
      this.image = document.createElement('img');
      this.image.classList.add('l-image');
      this.image.src = this.element.querySelector('*').dataset.image;
      this.image.style.visibility = 'hidden';
      this.viewer.appendChild(this.image);
    } else {
      this.video = document.createElement('video');
      this.video.classList.add('l-video');
      this.video.src = this.element.querySelector('*').dataset.video;
      this.video.style.visibility = 'hidden';
      this.video.loop = true;
      this.viewer.appendChild(this.video);
    }

    this.panel = document.createElement('div');
    this.panel.classList.add('l-panel');
    this.lookbook.appendChild(this.panel);

    if (Sniffer.isPhone) {
      this.button = document.createElement('div');
      this.button.classList.add('l-close');
      this.lookbook.appendChild(this.button);
    }

    if (this.type === 'image' || Sniffer.isDevice) {
      imagesLoaded(this.image, () => {
        this.set();
      });
    } else {
      this.video.play();
      setTimeout(() => {
        this.set();
      }, 500);
    }
  }

  set = () => {
    if (this.type === 'image' || Sniffer.isDevice) {
      this.object = this.image;
    } else {
      this.object = this.video;
    }

    this.sb = this.object.getBoundingClientRect();
    this.eb = this.element.getBoundingClientRect();

    gsap.set(this.object, { scale: this.eb.width / this.sb.width, });

    this.tx = (this.sb.width / (this.sb.width - this.eb.width)) * (this.eb.left - (0 * this.eb.width) / this.sb.width);
    this.ty = (this.sb.height / (this.sb.height - this.eb.height)) * (this.eb.top - (0 * this.eb.height) / this.sb.height);

    this.object.style.transformOrigin = `${this.tx}px ${this.ty}px`;

    gsap.set(this.viewer, {
      clip: `rect(${this.eb.top}px ${this.eb.left + this.eb.width}px ${this.eb.bottom}px ${this.eb.left}px)`
    });

    if (Sniffer.isPhone) {
      this.panel.style.marginTop = `${this.sb.height}px`;
      gsap.set(this.panel, {
        y: window.innerHeight,
      });
      gsap.to(this.page, {
        opacity: 0,
      });
    } else {
      gsap.set(this.panel, {
        xPercent: 100,
      });
    }

    if (this.type === 'image' || Sniffer.isDevice) {
      this.object.style.visibility = 'inherit';
      this.open();
    } else {
      gsap.to(this.object, {
        duration: 1,
        autoAlpha: 1,
        onComplete: this.open,
      });
    }
  }

  open = () => {
    this.ww = window.innerWidth;
    this.wh = window.innerHeight;

    gsap.to(this.object, {
      scale: 1,
      ease: 'exo',
      duration: 1.5,
      autoRound: false,
    });

    gsap.to(this.viewer, {
      delay: 0.1,
      ease: 'exo',
      duration: 1.5,
      autoRound: false,
      onComplete: this.load,
      clip: `rect(0px ${this.ww}px ${this.wh}px 0px)`,
    });
  }

  load = () => {
    this.data = this.element.dataset.products;

    this.request.open('POST', '' + document.location.origin + '/wp-admin/admin-ajax.php', true);
    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

    this.request.onload = () => {
      this.panel.innerHTML = this.request.response;
      this.panel.querySelector('h2').classList.add('f-hr');
      gsap.to(this.panel, {
        y: 0,
        xPercent: 0,
        ease: 'exo',
        duration: 1.5,
        onComplete: this.scroller,
      });
    };

    if (!Sniffer.isDevice) {
      this.mover();
      this.enter();
    }

    this.cursor.classList.add('c-close');

    this.request.send('action=lookbook&id=' + this.data + '');
  }

  mover = () => {
    window.addEventListener('mousemove', this.mouse);
    gsap.ticker.add(this.raf);
  }

  enter = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, { opacity: 1, duration: 0.5, });
  }

  leave = () => {
    this.cursor.setAttribute('class', '');
    gsap.to(this.cursor, { opacity: 0, duration: 0.5, });
  }

  scroller = () => {
    if (Sniffer.isPhone) {
      this.lookbook.style.overflowY = 'scroll';
      gsap.to(this.button, {
        duration: 1,
        autoAlpha: 1,
      });
      this.button.addEventListener('click', this.close);
      return;
    }

    document.body.style.overflow = '';

    this.products = document.querySelectorAll('.l-product');

    [...this.products].forEach((i) => {
      i.addEventListener('mouseenter', () => {
        this.enter();
        this.cursor.classList.add('c-more');
      });
      i.addEventListener('mouseleave', this.leave);
    });

    this.scroll = new Scroll();
    this.scroll.overlay();

    this.object.addEventListener('mouseleave', this.leave);
    this.object.addEventListener('click', this.close);
    this.object.addEventListener('mouseenter', () => {
      this.enter();
      this.cursor.classList.add('c-close');
    });
  }

  close = () => {
    if (this.closing === true) return;
    this.closing = true;

    gsap.ticker.remove(this.raf);

    if (!Sniffer.isDevice) {
      this.scroll.destroy();
      this.scroll = new Scroll(this.cache);
      this.scroll.init();
    }

    gsap.to(this.panel, {
      ease: 'exo',
      xPercent: 100,
      duration: 1.5,
      onComplete: this.reset,
    });

    if (Sniffer.isPhone) {
      gsap.to(this.page, {
        opacity: 1,
      });
      gsap.to(this.object, {
        ease: 'exo',
        xPercent: 100,
        duration: 1.5,
      });
      gsap.to(this.button, {
        ease: 'exo',
        scale: 0.001,
        duration: 0.5,
      });
      return;
    }

    gsap.to(this.object, {
      opacity: 0,
      duration: 0.5,
    });

    this.leave();
  }

  mouse = (e) => {
    this.tY = e.clientY;
  }

  raf = () => {
    this.pY += (this.tY - this.pY) * 0.07;
    this.transform = (this.wh - this.sb.height) * (this.pY / this.wh);
    this.object.style.transform = `translate3d(0, ${this.transform}px, 0)`;
  }

  reset = () => {
    this.closing = false;
    document.body.style.overflow = '';

    if (this.button) {
      this.button.remove();
      this.button = undefined;
    }

    window.removeEventListener('mousemove', this.mouse);

    this.tY = 0;
    this.pY = 0;

    this.lookbook.remove();

    this.image = undefined,
      this.video = undefined,
      this.object = undefined;
  }

  destroy = () => {
    if (this.lookbook) {
      this.lookbook.remove();
      this.lookbook = undefined;
      gsap.ticker.remove(this.raf);
    }
  }
}
