import gsap from 'gsap';
import imagesLoaded from 'imagesloaded';

export default class Grid {
  constructor() {
    this.page = document.querySelector('.a-page');
    this.box = document.querySelectorAll('.s-grid .box');
    this.media = document.querySelectorAll('.s-grid .box *'),

      this.total = 0;
  }

  init() {
    this.set();
    imagesLoaded(this.page, () => {
      this.events();
    });
  }

  events = () => {
    gsap.ticker.add(this.render);
  }

  set = () => {
    gsap.set(this.media, {
      autoAlpha: 0,
      y: window.innerWidth * 0.13889,
    });
  }

  render = () => {
    this.py = Math.abs(this.page.getBoundingClientRect().top);

    [...this.box].forEach((i) => {
      this.inview(i);
    });

    if (this.box === this.box.length) {
      gsap.ticker.remove(this.render);
    };
  }

  inview = (i) => {
    const b = i.getBoundingClientRect();

    const d = (window.innerHeight + this.py) - (b.top + this.py);
    const p = d / ((window.innerHeight + b.height) / 100);

    if (p > 0 && i.inview === undefined) {
      this.total += 1;
      i.inview = true;
      gsap.to(i.querySelector('*'), {
        y: 0,
        autoAlpha: 1,
        duration: 1.5,
        ease: 'power3.out'
      });
    }
  }
}
