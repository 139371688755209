// styles
import './index.scss';

// modules
import Sniffer from 'sniffer';

// instances
import { app } from './utils/store';

// utils
import Buttons from './utils/buttons';
import Effects from './utils/effects';
import Scroll from './utils/scroll';

// components
import Banner from './components/banner/banner';
import Cursor from './components/cursor/cursor';
import Grid from './components/grid/grid';
import HeaderVideo from './components/header/header-video';
import HeaderText from './components/header/header-text';
import Lookbook from './components/lookbook/lookbook';
import Text from './components/text/text';
import TextImage from './components/text-image/text-image';

// sniffer
Sniffer.addClasses(document.documentElement);

// cursor
app.cursor = new Cursor();
app.cursor.init();

// scroll
if (!Sniffer.isDevice) {
  app.scroll = new Scroll();
  app.scroll.init();
}

// buttons
app.buttons = new Buttons();
app.buttons.init();

// banner
if (document.querySelector('.s-banner')) {
  app.banner = new Banner();
  app.banner.init();
}

// lookbook
if (document.querySelector('.s-grid')) {
  app.lookbook = new Lookbook();
  app.lookbook.init();
}

// grid
if (document.querySelector('.s-grid')) {
  app.grid = new Grid();
  app.grid.init();
}

// Header
if (document.querySelector('.s-header-video')) {
  app.headervideo = new HeaderVideo();
  app.headervideo.init();
}

if (document.querySelector('.s-header-text')) {
  app.headertext = new HeaderText();
  app.headertext.init();
}

// text
if (document.querySelector('.s-text')) {
  app.text = new Text();
  app.text.init();
}

// text / image
if (document.querySelector('.s-text-image')) {
  app.textimage = new TextImage();
  app.textimage.init();
}

// effects
document.fonts.ready.then(() => {
  app.effects = new Effects();
  app.effects.init();
});

// resize
window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
